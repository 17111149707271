<template>
    <div class="download-footer">
        <img class="left" src="../../assets/luck77/luck_star.png" alt="">
        <div class="mid">
            <p class="text">Download the APP</p>
            <p class="res">
                <img src="../../assets/luck77/res-icon.png" alt="">
                <img src="../../assets/luck77/888-icon.png" alt="">
            </p>
            <p class="conment"><i class="five-star"></i>8000+</p>
        </div>
        <div class="right" @click="downloadApp"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { downloadApp } from "@/utils"

export default defineComponent({
  name: 'DownloadFooter',
  setup() {
    
    return {
        downloadApp
    }
  }
});
</script>

<style lang="scss" scoped>
.download-footer {
    background: #15F80166;
    background-image: url(../../assets/luck77/fbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
    width: 750px;
    height: 152px;
    position: fixed;
    bottom: -2px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 15px 20.57px 15px 19.43px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .left {
        width: 120px;
        height: 120px;
    }
    .mid {
        width: 100%;
        flex: 1;
        padding-left: 9.57px;
        .text {
            font-family: PingFang TC;
            font-size: 26px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: -0.05em;
            text-align: left;
            color: #FFFFFF;
        }
        .res {
            margin-top: 5px;
            display: flex;
            align-items: center;
            img:first-child {
                width: 102px;
                height: 28px;
            }
            img:last-child {
                margin-left: 14.43px;
                width: 172.54px;
                height: 42.69px;
            }
        }
        .conment {
            font-family: PingFang TC;
            font-size: 22.97px;
            font-weight: 400;
            letter-spacing: -0.05em;
            text-align: left;
            color: #fff;
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                width: 154.78px;
                height: 32.55px;
                margin-right: 12px;
                margin-left: -2px;
                background: url(../../assets/luck77/five-star.png) center top no-repeat;
                background-size: 100% 100%;
            }
 
        }
    }
    .right {
        width: 269px;
        height: 70px;
        background: url(../../assets/luck77/download-btn2.gif) center top no-repeat;
        background-size: 100% 100%;
    }
}
</style>