<template>
    <div class="h5-banner animated zoomInDown">
        <LogoH5 class="logo" /> 

        <div @click="downloadApp" class="download-btn"></div>
    </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LogoH5 from '@/components/Pkluck777/logo_h5.vue'
import { downloadApp } from "@/utils"

export default defineComponent({
  name: 'H5Banner',
  components: {
    LogoH5,
  },
  setup() {
    
    return {
        downloadApp
    }
  }
});
</script>

<style lang="scss" scoped>
.h5-banner {
    height: 748.43px;
    width: 100%;
    overflow: hidden;
    background: url(../../assets/luck77/banner.png) center bottom no-repeat;
    background-size: 768.38px 660.43px;
    position: relative;
    .logo {
        margin: 38px 0 0 117px;
        position: relative;
    }
    .download-btn {
        display: block;
        height: 120px;
        width: 442px;
        margin: 404px auto 0;
        background: url(../../assets/luck77/download-btn.gif) center top no-repeat;
        background-size: 100% 100%;
    }

}
@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
</style>
