<template>
    <div class="h5-luck-star">
        <img class="star-logo" src="../../assets/luck77/luck_star.png" alt="">
        <div class="star--main">
            <h4>NEWPK LUCK</h4>
            <p><i class="five-star"></i>9999+</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'H5LuckStar',
});
</script>

<style lang="scss" scoped>
.h5-luck-star {
    width: 554px;
    height: 150px;
    margin: 0 auto;
    position: relative;
    top: -2px;
    display: flex;
    .star-logo {
        width: 150px;
        height: 150px;
    }
    .star--main {
        flex: 1;
        width: 100%;
        padding-left: 40px;
        padding-top: 21px;
        h4 {
            font-family: Sarabun;
            font-size: 52.83px;
            font-weight: 700;
            line-height: 54px;
            text-align: center;
            text-align: left;
            background: linear-gradient(180deg, #FFFFFF 13.39%, #F6E6AD 100.89%);
            -webkit-background-clip: text;
            color: transparent;
        }
        p {
            font-family: PingFang TC;
            font-size: 22.97px;
            font-weight: 400;
            letter-spacing: -0.05em;
            text-align: left;
            color: #fff;
            line-height: 54.33px;
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                width: 154.78px;
                height: 32.55px;
                margin-right: 12px;
                background: url(../../assets/luck77/five-star.png) center top no-repeat;
                background-size: 100% 100%;
            }

        }
    }
}
</style>