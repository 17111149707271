import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-573ebe52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h5-banner animated zoomInDown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoH5 = _resolveComponent("LogoH5")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LogoH5, { class: "logo" }),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadApp && _ctx.downloadApp(...args))),
      class: "download-btn"
    })
  ]))
}