<template>
    <div class="h5-hot-list">
        <h4 class="h5-hot-list--title"></h4>
        <ul class="h5-hot-list--main">
            <li><img src="../../assets/luck77/game-1.png" alt=""></li>
            <li><img src="../../assets/luck77/game-2.png" alt=""></li>
            <li><img src="../../assets/luck77/game-3.png" alt=""></li>
            <li><img src="../../assets/luck77/game-4.png" alt=""></li>
            <li><img src="../../assets/luck77/game-5.png" alt=""></li>
            <li><img src="../../assets/luck77/game-6.png" alt=""></li>
            <li><img src="../../assets/luck77/game-7.png" alt=""></li>
            <li><img src="../../assets/luck77/game-8.png" alt=""></li>
            <li><img src="../../assets/luck77/game-9.png" alt=""></li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'H5HotList',
});
</script>

<style lang="scss" scoped>
ul, li {
    list-style: none;
}
.h5-hot-list {
    margin: 62px auto 0;
    width: 710px;
    height: max-content
    
}
.h5-hot-list--title {
    width: 506.89px;
    height: 48px;
    margin: 0 auto 64px;
    background: url(../../assets/luck77/hot.png) center top no-repeat;
    background-size: 100% 100%;
}
.h5-hot-list--main {
    width: 100%;
    height: 758.04px;
    border-radius: 27.57px;
    background: #276845B2;
    border: 2.3px solid;
    border-image-source: linear-gradient(180deg, #8EE2B5 4.17%, #2ED135 55.07%, #8EE2B5 55.08%, #2ED135 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    // border-image-source: linear-gradient(180deg, #FEFFB6 4.17%, #FFCF25 55.07%, #FBFD9B 55.08%, #FDC500 100%);
    li {
        width: 221px;
        height: 221px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>